const courseEnrollment = [
    { text: "COURSES", value: "title", width: "40%", sortable: false},
    { text: "INSTRUCTOR", value: "uploader.full_name", align: 'center', sortable: false},
    { text: "ENROLLEES", value: "enrollments_count", align: 'center', sortable: false},
    { text: "ACTION", value: "action", align: 'center', sortable: false}
]

const courseEnrollees = [
    { text: "USER", value: "name"},
    { text: "EMAIL", value: "email"},
    { text: "DATE ENROLLED", value: "date_enrolled", align: 'center'},
]

const pendingCourseEnrollment = [
    { text: "USER", value: "name"},
    { text: "ENROLLED BY", value: "enrolled_by", align: 'center'},
    { text: "DATE ENROLLED", value: "date_enrolled", align: 'center'},
    { text: "OFFICIAL RECEIPT", value: "official_receipt", align: 'center', sortable: false},
    { text: "ACTION", value: "action", align: 'center', sortable: false}
]

const approvedCourseEnrollment = [
    { text: "USER", value: "name"},
    { text: "ENROLLED BY", value: "enrolled_by", align: 'center'},
    { text: "DATE ENROLLED", value: "date_enrolled", align: 'center'},
    { text: "DATE APPROVED", value: "date_approved", align: 'center'},
    { text: "OFFICIAL RECEIPT", value: "official_receipt", align: 'center', sortable: false},
]

const disqualifiedCourseEnrollment = [
    { text: "USER", value: "name"},
    { text: "ENROLLED BY", value: "enrolled_by", align: 'center'},
    { text: "DATE ENROLLED", value: "date_enrolled", align: 'center'},
    { text: "OFFICIAL RECEIPT", value: "official_receipt", align: 'center', sortable: false},
]

export { courseEnrollment, pendingCourseEnrollment, approvedCourseEnrollment, disqualifiedCourseEnrollment, courseEnrollees }